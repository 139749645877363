<template>
  <div class="tp-header-search pl-70">
    <form @submit.prevent="handleSubmit">
      <div class="tp-header-search-wrapper d-flex align-items-center">
        <div class="tp-header-search-box">
          <input
            type="text"
            :placeholder="$t('Search for Products...')"
            v-model="searchText"
          />
        </div>
        <div class="tp-header-search-category">
          <ui-nice-select
            :options="formattedSearchCategories"
            :name="$t('Select Category')"
            :default-current="0"
            @onChange="changeHandler"
          />
        </div>
        <div class="tp-header-search-btn">
          <button type="submit" aria-label="search" id="search_btn">
            <SvgSearch />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";

const router = useRouter();
let searchText = ref<string>("");
let productType = ref<string>("");

const { formattedSearchCategories, categories } = storeToRefs(useHomeStore());
const changeHandler = (e: { value: string; text: string }) => {
  productType.value = categories.value.find((item) => item.id === e.value);
};

// handleSubmit
const localePath = useLocalePath();
const handleSubmit = () => {
  if (!searchText.value && !productType.value) {
    return;
  } else if (searchText.value && productType.value) {
    router.push(
      localePath(
        `/category/${productType.value.slug}?searchText=${searchText.value}`
      )
    );
  } else if (searchText.value && !productType.value) {
    router.push(localePath(`/shop?searchText=${searchText.value}`));
  } else if (!searchText.value && productType.value) {
    router.push(localePath(`/category/${productType.value.slug}`));
  } else {
    router.push(localePath(`/shop`));
  }
};
</script>
