<template>
  <div class="tp-header-category tp-category-menu tp-header-category-toggle">
    <button
      @click="handleActive"
      class="tp-category-menu-btn tp-category-menu-toggle"
    >
      <span>
        <SvgDropdown />
      </span>
      {{ $t("All Categories") }}
    </button>
    <Transition>
      <nav
        v-if="isActive"
        class="tp-category-menu-content"
        @mouseleave="handleActive"
      >
        <ul>
          <li v-for="(item, i) in categories" class="has-dropdown" :key="i">
            <nuxt-link-locale
              :href="`/category/${item.slug}`"
              class="cursor-pointer"
            >
              <span v-if="item.default_img">
                <NuxtImg
                  loading="lazy"
                  :src="item.default_img"
                  alt="cate img"
                  style="width: 50px; height: 50px; object-fit: contain"
                />
              </span>
              {{ item.name }}
            </nuxt-link-locale>

            <ul v-if="item.children.length" class="tp-submenu">
              <li v-for="(child, i) in item.children" :key="i">
                <nuxt-link-locale
                  :href="`/category/${child.slug}`"
                  class="cursor-pointer"
                  @click="handleParentCategory(child.slug)"
                  >{{ child.name }}</nuxt-link-locale
                >
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import category_data from "@/data/category-data";
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
const { categories } = storeToRefs(useHomeStore());

const router = useRouter();
const category_items = category_data.filter(
  (c) => c.productType === "electronics"
);
let isActive = ref<boolean>(false);
// handle active
const handleActive = () => (isActive.value = !isActive.value);
const localePath = useLocalePath();
// handle parent
const handleParentCategory = (value: string) => {
  const newCategory = value;
  router.push(localePath(`/category/${newCategory}`));
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
