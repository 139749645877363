<template>
  <div
    class="tp-header-main-right d-flex align-items-center justify-content-end"
  >
    <div class="tp-header-login d-none d-lg-block">
      <nuxt-link-locale
        :to="isAuth ? '/profile' : '/login'"
        class="d-flex align-items-center"
      >
        <div class="tp-header-login-icon">
          <template v-if="user">
            <span>
              <NuxtImg
                loading="lazy"
                format="webp"
                :src="profileImage"
                alt="user"
              />
            </span>
          </template>
          <template v-else>
            <span>
              <SvgUser />
            </span>
          </template>
        </div>

        <div class="tp-header-login-content d-none d-xl-block">
          <span v-if="user">
            {{ $t("welcome", { user: user?.name }) }}
          </span>
          <span v-else>{{ $t("login") }} </span>
          <h5 class="tp-header-login-title" v-if="user">
            {{ $t("my_account") }}
          </h5>
        </div>
      </nuxt-link-locale>
    </div>
    <div class="tp-header-action d-flex align-items-center ml-50">
      <!-- <div class="tp-header-action-item d-none d-lg-block">
        <nuxt-link-locale href="/compare" class="tp-header-action-btn">
          <svg-compare />
          <span class="tp-header-action-badge">{{ compareItemsCount }}</span>
        </nuxt-link-locale>
      </div> -->
      <div class="tp-header-action-item d-none d-lg-block">
        <nuxt-link-locale href="/wishlist" class="tp-header-action-btn">
          <svg-wishlist />
          <span class="tp-header-action-badge">{{
            wishlistStore.wishlists.length
          }}</span>
        </nuxt-link-locale>
      </div>
      <div class="tp-header-action-item">
        <button
          @click="cartStore.handleCartOffcanvas"
          type="button"
          class="tp-header-action-btn cartmini-open-btn"
        >
          <svg-cart-bag />
          <span class="tp-header-action-badge">{{
            cartStore.cart_products.length
          }}</span>
        </button>
      </div>
      <div class="tp-header-action-item d-lg-none">
        <button
          @click="utilsStore.handleOpenMobileMenu"
          type="button"
          aria-label="menu"
          class="tp-header-action-btn tp-offcanvas-open-btn"
        >
          <SvgMenuIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from "@/pinia/useCartStore";
import { useWishlistStore } from "@/pinia/useWishlistStore";
import { useUtilityStore } from "@/pinia/useUtilityStore";
import { useAuthStore } from "@/pinia/useAuthStore";
import { storeToRefs } from "pinia";
import { useCompareStore } from "@/pinia/useCompareStore";

const cartStore = useCartStore();
const wishlistStore = useWishlistStore();
const utilsStore = useUtilityStore();
const { user, isAuth } = storeToRefs(useAuthStore());
const { compareItemsCount } = storeToRefs(useCompareStore());
const profileImage = computed(() => {
  if (user.value?.image.length) return user.value.image;
  return "https://swift-ecommerce.urusglobal.com/img/users/user-10.jpg";
});
</script>
